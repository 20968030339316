export class Header {

  private _threshold = 10;

  private _detachedClass = 'detached';

  private _selector = '.header';

  private _target: HTMLElement;

  constructor() {
    // get a reference to the header
    this._target = document.querySelector(this._selector);
  }

  /**
   * add listeners
   */
  register() {
    // listen to scroll offset
    window.addEventListener('scroll', (event: Event) => this._handleScroll(event), false);
  }

  /**
   * handle the body scroll event
   * @param {Event} event
   * @private
   */
  private _handleScroll(event: Event) {
    // add the detached class if the scrolling exceeds the threshold value
    if (window.pageYOffset >= this._threshold) {
      this._target.classList.add(this._detachedClass);
    } else {
      this._target.classList.remove(this._detachedClass);
    }
  }

}
