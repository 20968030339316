import { Navigation } from './components/navigation';
import { Header } from './components/header';
import { Mailer } from './components/mailer';
import Glide from '@glidejs/glide';


// intializes the application
const initialize = () => {
  const mailer = new Mailer();
  mailer.register();
  // initialize header scroll
  const header = new Header();
  header.register();

  // set up navigation
  const navigation = new Navigation();
  navigation.register();


  document.getElementById('newsletter-cb').addEventListener('click', data => {
    document.getElementById('submit-button').disabled = !data.target.checked;
  });

  new Glide('#container_slider', {
    autoplay: 5000,
    hoverpause: false,
    animationDuration: 1000,
    type: 'carousel'
  }).mount();
  new Glide('#maschinenpark_slider', {
    autoplay: 5000,
    hoverpause: false,
    animationDuration: 1000,
    type: 'carousel'
  }).mount();
};

// listen to the dom to be loaded and parsed
document.addEventListener('DOMContentLoaded', () => initialize(), false);
