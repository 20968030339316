// TODO: use polyfills.ts
import 'intersection-observer';

export class Navigation {

  private _debug = false;

  private _activeClass = 'active';

  private _selector = 'a[href^="#"]';

  private _anchors = new Map<HTMLElement, HTMLElement>();

  private _context: HTMLElement;

  private _observer: IntersectionObserver;

  private _observerOptions: IntersectionObserverInit = {
    root: null,
    rootMargin: '0px',
    // TODO: align thresholds, s. https://developer.mozilla.org/en-US/docs/Web/API/IntersectionObserver/IntersectionObserver#Parameters
    threshold: [.1, .2, .3, .4, .5, .6, .7, .8, .9, 1]
  };

  constructor({ contextSelector = 'body', debug = false } = {}) {
    // get a reference to the context (can be limited e.g. to `.header,.footer`)
    this._context = document.querySelector(contextSelector);

    // set the debug flag
    this._debug = debug;

    // find all anchor links and targets and map them
    this._collectAnchors();
  }

  /**
   * add listeners
   */
  register() {
    // register intersection observer
    // @ref https://davidwalsh.name/intersection-observers
    // @ref https://codepen.io/tutsplus/pen/GyyWvw
    this._observer = new IntersectionObserver(
      (entries) => this._handleIntersections(entries),
      this._observerOptions
    );

    // register the collected anchors
    this._registerAnchors();
  }

  private _collectAnchors() {
    // find all anchors targeting links
    const anchorLinks = Array.from(this._context.querySelectorAll(this._selector));
    // now find all targets and map 'em
    anchorLinks.forEach((anchorLink: HTMLAnchorElement) => {
      const targetId = anchorLink.hash.replace(/^#/, '');
      const anchorTarget = document.getElementById(targetId);

      // add to map
      this._anchors.set(anchorTarget, anchorLink);
    });

    // show debug information
    if (this._debug) {
      // add debug styles
      const style = document.createElement('style');
      const sheet = document.head.appendChild(style).sheet as CSSStyleSheet;
      this._anchors.forEach((link, anchor, anchors) => {
        const progress = (sheet.cssRules.length + 1) / anchors.size;
        sheet.insertRule(`#${anchor.id}:after {
          content: '${anchor.id}';
          position: absolute;
          top: 0;
          right: 0;
          z-index: 9999;
          display: block;
          height: 100%;
          min-height: 20px;
          width: 20px;
          color: white;
          background-color: rgb(255, ${255 * progress * .5}, ${255 * progress * .5});
          font: 11px/20px Monace, Monospaced, Courier;
          text-orientation: mixed;
          writing-mode: vertical-rl;
        }`);
      });
    }
  }

  private _registerAnchors() {
    // add anchor targets to observer
    this._anchors.forEach((anchorLink, anchorTarget) => this._observer.observe(anchorTarget));
  }

  private _handleIntersections(entries: IntersectionObserverEntry[]) {
    // find the most intersecting target
    const currentIntersection = entries.reduce((previous, current) => {
      return previous.intersectionRatio > current.intersectionRatio ? previous : current;
    });

    // set or remove active classes
    this._anchors.forEach((anchorLink, anchorTarget) => {
      // remove active classes from links
      if (currentIntersection.target.id !== anchorTarget.id) {
        anchorLink.classList.remove(this._activeClass);
      }
      // or set active
      else {
        anchorLink.classList.add(this._activeClass);
      }
    });

    // show debug information
    if (this._debug) {
      console.info(entries.map((entry) => `${entry.target.id}: ${entry.intersectionRatio}`), currentIntersection.target.id);
    }
  }

}
