export class Mailer {

  sendData(form) {
    document.getElementById('mail-success').style.display = 'none';
    document.getElementById('mail-error').style.display = 'none';
    var XHR = new XMLHttpRequest();

    // Bind the FormData object and the form element
    var FD = new FormData(form);

    // Define what happens on successful data submission
    XHR.addEventListener('load', (event) => {
      const respone = JSON.parse(event.target.responseText);
      if (respone.hasOwnProperty('status') && respone['status'] !== 'pending') {
        document.getElementById('mail-error').style.display = 'inherit';
      } else {
        document.getElementById('mail-success').style.display = 'inherit';
      }
      console.log(respone);
      this.cleanMailForm();
    });

    // Define what happens in case of error
    XHR.addEventListener('error', function (event) {
      alert('Oops! Something went wrong.');
    });

    // Set up our request
    XHR.open('POST', 'mail.php');
    // XHR.open('POST', 'http://localhost:8081');

    // The data sent is what the user provided in the form
    XHR.send(FD);
  }

  cleanMailForm() {
    document.getElementById('mail-address').value = '';
    document.getElementById('mail-name').value = '';
    document.getElementById('newsletter-warteliste').checked = false;
    document.getElementById('newsletter-cb').checked = false;
    document.getElementById('submit-button').disabled = true;
  }

  register() {
    // Access the form element...
    const form = document.getElementById('newsletter-form');

    // ...and take over its submit event.
    form.addEventListener('submit', (event) => {
      event.preventDefault();

      this.sendData(form);
    });
  }
}
